import React from "react";
import { navigate } from "gatsby";
import { Row, Col, Card, Image } from "react-bootstrap";
import "../../styles/components/Brand/style.scss"

const BrandComponent = ({
  image,
  cardImage,
  heading1,
  description,
  heading2,
  subDescriptions,
  amount,
  imageTitle,
  keyMembers,
  address,
  className,
  children,
  blogItems
}) => {
  const handleNavigate = (item) => {
    if (item.type == "blog_post") {
      navigate(`/blog/${item.uid}`);
    } else {
      navigate(`/press-and-media/${item.uid}`);
    }
  };
  return (
    <div className="brand">
      <Row className={`pt-80 pb-top-40 mw-1440 mx-auto  padding-mobile ${className}`}>
        <Col md={6}>
          <div className="brand-image-container">
            <Image src={image} fluid className="brand-image-margin" style={{maxHeight: '150px'}} />
          </div>

          <div className="brand-text-container">
            {heading1 && <h4 className="brand-heading1">{heading1}</h4>}
            {description && <p className="brand-description">{description}</p>}
            {heading2 && <h4 className="brand-heading1">{heading2}</h4>}
            {subDescriptions && <p className="brand-description">{subDescriptions}</p>}
            {children && <div className="brand-description">
              {children}
            </div>}
          </div>
        </Col>
        <Col md={6}>
          <Card className="brand-card-border">
            {cardImage && <Card.Img
              className="brand-card-image"
              variant="top"
              src={cardImage}
              alt="card image"
            />}
            <Card.Body className={cardImage ? "card-body-no-top-border" : "card-body"}>
              <div className="d-flex gap-4">
                <div>
                  <h4 className="mb-0 font-24">{imageTitle}</h4>
                  <h5>{address}</h5>
                  <h5 className="brand-card-heading-3"> {amount}</h5>
                  <p />
                  <h5 className="font-24"> KEY MEMBERS</h5>
                  <ul className="mb-0">
                    {Array.isArray(keyMembers) && keyMembers.length > 0 &&
                      keyMembers.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                  </ul>
                </div>
                {blogItems !== undefined && blogItems.length !== 0 && (
                  <div className="border-start pl-4 px-4 brand-card-blog">
                    {
                      blogItems.map((blogItem, index) => {
                        return (
                          <div className="brand-card-blog-item" key={index}>
                            <p className="mb-0 brand-card-date">{blogItem?.date.getMonth() + "/" + blogItem.date.getDate() + "/" + blogItem.date.getFullYear()}</p>
                            <p className="brand-card-title">
                              <a onClick={() => handleNavigate(blogItem)}>
                                {blogItem?.title?.length > 95 ? blogItem?.title.substring(0, 94) + "..." : blogItem?.title}
                              </a>
                            </p>
                          </div>
                        )
                      })
                    }
                </div>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default BrandComponent;
